import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import './styles/terms.scss'

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const PolicyPage = () => {
  const query = useStaticQuery(graphql`
    query PolicyData {
      allContentfulPolicyPage {
        edges {
          node {
            content {
              raw
            }
          }
        }
      }
    }
  `)
  const data = query.allContentfulPolicyPage.edges[0].node.content

  return (
    <div className="terms">
      <Layout>
        <div className="grid-parent">
          <div className="terms__inner">
            <h2 className="title">RAINRAIN GALLERY</h2>
            <h3 className="sub-title">Privacy policy</h3>
            <div className="content">
              {renderRichText(data, options)}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default PolicyPage
